class ContactSelector {
    constructor() {
        this.init();
    }

    init() {
        const selectors = document.querySelectorAll("[data-contact-selector]")

        if (!selectors) {
            // There are no matching selectors, return early
            return
        }

        selectors.forEach(sel => {
            $(sel).selectize({
                valueField: "id",
                labelField: "name",
                searchField: "name",
                create: false,
                maxItems: 1,
                options: [{ "id": sel.value, "name": sel.dataset.initial }],
                render: {
                    option: (item, escape) => {
                        return `<div>${escape(item.name)} (${escape(item.email)})</div>`
                    },
                    item: (item, escape) => {
                        if (item.email) {
                            return `<div>${escape(item.name)} (${escape(item.email)})</div>`
                        } else {
                            return `<div>${escape(item.name)}</div>`
                        }
                    },
                },
                load: (query, callback) => {
                    if (!query.length) return callback();
                    $.ajax({
                        url: "/contact-search/?query=" + encodeURIComponent(query),
                        type: "GET",
                        error: () => {
                            callback()
                        },
                        success: (res) => {
                            callback(res.results)
                        }
                    })
                }
            })
        })
    }
}

export default ContactSelector;
