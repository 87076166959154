class Basket {
    constructor(basketName) {
        this.basketName = `ukge_basket_${basketName}`;

        // If this basket doesn't exist, initialise it
        if (this._getBasket() === null) {
            this.clear()
        }
    }

    _getBasket() {
        return JSON.parse(window.localStorage.getItem(this.basketName))
    }

    _setBasket(obj) {
        window.localStorage.setItem(this.basketName, JSON.stringify(obj))

    }

    getItem(key) {
        let currentBasket = this._getBasket()
        let items = currentBasket.filter((obj) => (obj.key === key))
        if (items.length > 0) {
            return items[0]
        }
        return null
    }

    addItem(key, name, price, metadata, quantity = 1) {
        // If the item is already in the basket, increase the quantity by the value given
        const existingItem = this.getItem(key)
        if (existingItem) {
            this.setQuantity(key, existingItem.quantity += quantity)
        } else {
            let currentBasket = this._getBasket()
            currentBasket.push({ key, name, price, metadata, quantity })
            this._setBasket(currentBasket)
        }
    }

    setQuantity(key, quantity) {
        let currentBasket = this._getBasket()
        if (quantity === 0) {
            this.removeItem(key)
        } else {
            let item = currentBasket.filter((obj) => (obj.key === key))[0]
            item.quantity = quantity;
            this._setBasket(currentBasket)
        }
    }

    removeItem(key) {
        let currentBasket = this._getBasket()
        currentBasket = currentBasket.filter((obj) => (obj.key !== key))
        this._setBasket(currentBasket)
    }

    getItems() {
        return this._getBasket()
    }

    getTotal() {
        let currentBasket = this._getBasket()
        return currentBasket.reduce((acc, item) => acc + +(item.price * item.quantity), 0)
    }

    clear() {
        this._setBasket([])
    }

}

export default Basket;
