import "./css/tailwind.css";

import "htmx.org";
import throttle from 'underscore/modules/throttle';
import ContactSelector from "./js/components/contact-selector";
import Basket from "./js/components/basket";

window.Basket = Basket
window.throttle = throttle;

window.addEventListener("DOMContentLoaded", function () {
    const contactSelector = new ContactSelector()
});